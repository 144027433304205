<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { vaultService } from '../../../services/vault.service';

import {
  paymentServiceMethods,
  notificationMethods
} from "@/state/helpers";

export default {
    page: {
    title: "Vault",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Till authorisations",
      items: [
        {
          text: "Smart Exchange"
        },
                {
          text: "Till"
        },
        {
          text: "Authorisations",
          active: true
        }
      ],
      transactionData: [],
      variableObject:{},
      modalTitle:"",
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "transactionReference",
      sortDesc: false,
      fields: [

        { key: "id", sortable: true, label: " ID" },
        { key: "tellerId", sortable: true, label:"Teller Id" },
        { key: "transactionType",sortable: true, label: "Transaction Type" },
        { key: "transactionAmount", sortable: true, label: "Amount" },
        { key: "currencyType", sortable: true, label: "Currency" },
        { key: "localDateTime", sortable: true, label: "Time" },
        { key: "action", sortable: false },
      ],
      shopId: JSON.parse(localStorage.getItem('user')).shopId,
      responseMessage:"",
      authorisationFailed: false,
      authorisationSuccess:false,
    };
  },

  created() {
    this.loadClosureReuquests()
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.transactionData.length;
    },

    notification() {
        return this.$store ? this.$store.state.notification : null;
      }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.transactionData.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    ...paymentServiceMethods,
    ...notificationMethods,

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async loadClosureReuquests() {
        try {
          await vaultService.tillClosureRequests(this.shopId).then(response=>{
            if(response.responseBody.length>0){
                this.transactionData = response.responseBody;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },

      async authorizeTransaction(transaction, decision) {
            const requestBody =   {
                "operation" : "supervisor activity",
                "accessToken" : localStorage.getItem('access_token'),
                "requestBody" :
                {
                    "shopId" : transaction.shopId,
                    "amount" :  transaction.transactionAmount,
                    "agentId" : transaction.tellerId,
                    "currencyType" :  transaction.currencyType,
                    "supervisorId": JSON.parse(localStorage.getItem('user')).id,
                    "transactionId" : transaction.id,
                    "transactionType" : decision
                }
            };
            
            var status = false;
            try {
                await vaultService.supervisorActivity(requestBody).then(response=>{
                    if(response.status==="SUCCESS"){
                        this.authorisationSuccess = true;
                        this.responseMessage = response.message;
                        status = true;
                        this.loadClosureReuquests();
                    }else{
                        this.notificationType = "danger";
                        this.noticationIcon = "mdi-block-helper";
                        this.responseMessage = response.message;
                        this.authorisationFailed = true;
                        status = false;
                        this.loadClosureReuquests();
                    }
                });
                return status
            } catch (error) {
                this.notificationType = "danger";
                this.noticationIcon = "mdi-block-helper";
                this.authorisationFailed = true;
                this.responseMessage =error;
                console.log(error);
                return false;
            }
        },


    storeState(object, title){
      this.variableObject = object;
      this.modalTitle = title;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


  <div class="card">
    <div class="card-body">
      <h5 class="card-title badge-soft-danger pb-2 pt-2 pl-2" v-if="authorisationFailed">{{responseMessage}}</h5>
      <h5 class="card-title badge-soft-success pb-2 pt-2 pl-2" v-if="authorisationSuccess">{{responseMessage}}</h5>
                    
      <h4 class="card-title mb-4">Till Closure Authorisation</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="transactionData"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:cell(action)="row">
              <a
                @click="authorizeTransaction(row.item, 'approve-close-of-till')"
                href="javascript:void(0);"
                class="mr-3 text-success"
                v-b-tooltip.hover
                title="Approve close of Till"
              >
                <i class="mdi mdi-check font-size-18" ></i>
              </a>
              
              <a
                @click="authorizeTransaction(row.item, 'reject-close-of-till')"
                href="javascript:void(0);"
                class="mr-3 text-danger"
                v-b-tooltip.hover
                title="Decline close of till"
              >
                <i class="mdi mdi-cancel font-size-18" ></i>
              </a>

          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </Layout> 
</template>